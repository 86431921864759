import { lazy, Suspense } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import { useQuery } from '@tanstack/react-query';

import './App.css';
import useLoadingBar from './hooks/useLoadingBar';
import Header from './components/header';
import Home from './pages/home';
import ServicesStatus from './pages/status';
import Footer from './components/footer';
import FullPageSpinner from './components/fullpage-spinner';
import ScrollToTop from './components/scroll-to-top';
import { useUserUpdateListener } from './hooks/useUserUpdateListener';
import { getServicesStatus } from './actions/status';

const Unauthorized = lazy(() => import('./pages/unauthorized'));
const ErrorPage = lazy(() => import('./pages/error'));

function App() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const loadingRef = useLoadingBar();

  useQuery(['status'], getServicesStatus, {
    refetchInterval: 120000,
    refetchOnWindowFocus: false,
    retry: 3,
  });
  useUserUpdateListener();

  if (!isAuthenticated && window.location.pathname === '/ciam-continue') {
    loginWithRedirect();
    return <FullPageSpinner />;
  }

  const shouldRenderFooter = !window.location.pathname.includes('/status');

  return (
    <div id="home" className="relative">
      <Router>
        <>
          <LoadingBar color="#f11946" ref={loadingRef} />
          <Suspense fallback={<FullPageSpinner />}>
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/unauthorized" element={<Unauthorized />} />
              <Route path="/status" element={<ServicesStatus />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
            <ScrollToTop />
          </Suspense>
          {shouldRenderFooter && <Footer />}
        </>
      </Router>
    </div>
  );
}

export default App;
